<template>
  <div class="serveBindingManage">
    <ds-header title="年终活动管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <a-select
          placeholder="请选择项目"
          v-model="searchForm.projectId"
          style="width: 200px"
        >
          <a-select-option v-for="item in projectList" :key="item.id">
              {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="活动名称：">
        <a-input v-model.trim="searchForm.activityTitle" placeholder="请输入活动名称" class="inputClass" />
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addActivity"> <a-icon type="plus" /> 新增 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="iconUrl" slot-scope="text, record" class="action">
          <div class="imgContainer">
            <img :src="record.iconUrl" v-if="record.iconUrl"/>
          </div>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record.id)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="edit(record.id)"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="updateStatus(record)"> {{ record.status === 1 ? '启用' : '禁用' }} </a-button>
          <a-button class="btnClass" type="link" @click="copyData(record)"> 链接地址 </a-button>
          <a-button class="btnClass" style="color: red" type="link" @click="downLoadData(record.id, record.type)"> 活动数据 </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getYearEndActivityList,updateActivityStatus } from '@/api/yearEndActivities'
import { projectName } from "@/api/modularManagement";
import moment from 'moment'
export default {
  name: "jackpotManagement",
  components: {

  },
  data() {
    return {
      searchForm: {
        projectId: undefined,
        activityTitle: '',
      },
      projectList: [],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "活动ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "所属项目",
            dataIndex: "projectNameList",
            key: "projectNameList",
          },
          {
            title: "活动名称",
            dataIndex: "activityTitle",
            key: "activityTitle",
          },
          {
            title: "活动创建时间",
            dataIndex: "createTime",
            key: "createTime",
            customRender(text) {
              return moment(text).format('YYYY-MM-DD HH:mm:ss')
            },
          },
          {
            title: "状态",
            dataIndex: "status",
            customRender(text) {
              return text === 1 ? "禁用" : "启用";
            },
          },
          {
            title: "活动时间",
            customRender(text, record, index) {
              return moment(record.startTime).format('YYYY/MM/DD HH:mm') + '--' +  moment(record.endTime).format('YYYY/MM/DD HH:mm')
            },
          },
          {
            title: "操作",
            key: "action",
            width: "260px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      }
    };
  },
  created() {
    this.searchFormList();
    this.getProjectList();
  },
  methods: {
    // 获取所有项目
    getProjectList() {
      projectName().then(res => {
        if (res.code === '200') {
          this.projectList = res.data.items
        }
      })
    },
    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    // 重制
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },

    // 新增奖池
    addActivity () {
      this.$router.push({
        path: '/yearEndActivities/activityManagement/add',
        query: {
          type: 'add'
        }
      })
    },

    // 编辑
    edit(id) {
      this.$router.push({
        path: '/yearEndActivities/activityManagement/add',
        query: {
          type: 'edit',
          id: id
        }
      })
    },
    // 查看
    view(id) {
      this.$router.push({
        path: '/yearEndActivities/activityManagement/add',
        query: {
          type: 'view',
          id: id
        }
      })
    },
    // 列表
    async searchFormList() {
      let data = {};
      data.activityTitle = this.searchForm.activityTitle
      data.projectId = this.searchForm.projectId
      data.currentPage = this.table.pagination.current;
      data.pageSize = this.table.pagination.pageSize
      const res = await getYearEndActivityList(data);
      if (res.code === '200') {
        this.table.dataInfo = res.data.data;
        this.table.pagination.total = res.data.count;
      }
    },

    // 复制
    copyData(data) {
      if (data.h5Url) {
        const url = data.h5Url
        let oInput = document.createElement('input')
        oInput.value = url
        document.body.appendChild(oInput)
        oInput.select() // 选择对象;
        document.execCommand('Copy') // 执行浏览器复制命令
        this.$message.success("复制成功！")
        oInput.remove()
      } else {
        this.$message.error("复制错误！")
      }
    },

    // 禁用启用
    updateStatus (data) {
      const params = {
        activityId: data.id,
        state: data.status === 1 ? 0 : 1
      }
      const that = this
      this.$confirm({
        title: `确定要${data.status === 1 ? '启用' : '禁用'}该条数据吗？`,
        onOk() {
          updateActivityStatus(params).then(res => {
            if (res.code === '200') {
              that.$message.success("操作成功！")
              that.searchFormList()
            } else {
              that.$message.error("操作失败！")
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    // 活动数据
    downLoadData(id, type) {
      this.$router.push({
        path: '/yearEndActivities/activityManagement/activitiesData',
        query: { id: id, type: type }
      })
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.inputClass {
  width: 200px;
}
.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
